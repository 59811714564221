import React, { useState } from "react"
import "./PropertyCardSection.scss"
import teamImage from "../../images/staticTeam.png"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { getWhatsAppURL, numberFormat } from "../common/site/utils"
import SocialShareComponent from "../SocialShareComponent/SocialShareComponent"
import { Link } from "gatsby"
import { bookViewing } from "../../site/urls"
import useCompanyInfo from "../../hooks/useCompanyInfo"

const PropertyCardSection = ({ data, teamData }) => {
  const { isMobile, isLargeScreen } = useDeviceMedia()
  const [shareIcons, setShareIcons] = useState(false)

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const { phone } = useCompanyInfo()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
  }

  const handleClick=()=>{
    const getId=document.getElementById("rental-yield_calc");
    getId.scrollIntoView({behavior:"smooth", block:"start"})
  }

  return (
    <div className="property-side-wrapper">
      {!isLargeScreen && (
        <>
          <div className="property-card-content">
            <h3>AED {numberFormat(data?.price)}</h3>
            {data?.search_type === "sales" &&
            <p>
              {" "}
              <i className="icon calculator-icon" />{" "}
              <span onClick={()=>handleClick()}>Calculate the rental yield of this property*</span>
            </p>
            }
            
            <div className="property-contact-ctas">
              {teamData?.mobile_no?
                <a href={"tel:" + teamData.mobile_no} className="button button-black">
                  <i className="icon call-icon" /> <span>call</span>
                </a>
              : 
                <a href={"tel:" + phone} className="button button-black">
                  <i className="icon call-icon" /> <span>call</span>
                </a>
              }
                <Link
                  className="button button-black middle-button"
                  to={bookViewing}
                  state={{
                    data: {
                      property: data,
                      shareurl,
                    },
                  }}
                >
                  <i className="icon envelope-icon" /> <span>email</span>
                </Link>
              {teamData?.mobile_no ? 
                <a href={getWhatsAppURL(teamData.mobile_no)} target="_blank" className="button button-black">
                  <i className="icon white-Whatsapp" />
                  <span>whatsapp</span>
                </a>
                : 
                <a href={getWhatsAppURL(phone)} target="_blank" className="button button-black">
                  <i className="icon white-Whatsapp" />
                  <span>whatsapp</span>
                </a>
              }
            </div>
              
            <div className="share-property" onClick={() => openShareicons()}>
              <i className="icon black-share-icon" />{" "}
              <span>Share this Property</span>
              {shareIcons && (
                <SocialShareComponent
                  openShareicons={openShareicons}
                  shareurl={shareurl}
                />
              )}
            </div>
          </div>

          {teamData && (
            <div className="property-team-block">
              <div className="team-content-module">
                <div className="img-team-section">
                  <img src={teamData?.image?.url} alt="property-team" />
                </div>
                <div className="property-team-info">
                  <h4>{teamData?.name}</h4>
                  <p className="desigination">{teamData?.designation}</p>
                  <p className="speaks">Speaks {teamData?.languages}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default PropertyCardSection
