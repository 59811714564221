import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./PropertyInformation.scss"
import parse from "html-react-parser"
import ReadMore from "../ReadMore/ReadMore"
import Map from "../PropertyMap/Map"
import RentalYieldCalculator from "../RentalYieldCalculator/RentalYieldCalculator"

import PropertyCardSection from "../PropertyCardSection/PropertyCardSection"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { graphql, Link, useStaticQuery } from "gatsby"
import SocialShareComponent from "../SocialShareComponent/SocialShareComponent"
import { getWhatsAppURL, numberFormat } from "../common/site/utils"
import { bookViewing, contactURL, propertyForRentDetailURL, propertyForSaleDetailURL } from "../../site/urls"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromRight,
  fadeInFromLeft,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import clsx from "clsx"
import useCompanyInfo from "../../hooks/useCompanyInfo"

const PropertyInformation = props => {
  const staticData = useStaticQuery(graphql`
  query getimage {
    glstrapi {
      globalModule {
        mortgage_calc_image {
          url
        }
      }
    }
  }
  `)


  const staticImage=staticData.glstrapi.globalModule?.mortgage_calc_image

  const data = props.propertyData
  const teamData = props.teamData
  const { isMobile, isLargeScreen } = useDeviceMedia()
  const [shareIcons, setShareIcons] = useState(false)

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
  }

  const handleClick = () => {
    const getId = document.getElementById("rental-yield_calc")
    getId.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const { phone } = useCompanyInfo()

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const sqmeter = props.propertyData?.floorarea_max * 0.092903
 
  const capitalizeFirstLetter=(str)=>{
    return str?.charAt(0)?.toUpperCase()+str?.slice(1)
  }

  let action =""
  if(props.propertyData?.search_type === "sales"){
    action="for sale"
  }
  else{
    action="for rent"
  }

  const building =props.propertyData?.building?.length > 0 ? props.propertyData?.building[0] : ""

  const titleText=`${building} ${action} with ${props.propertyData?.bedroom} bedrooms in ${props.propertyData?.display_address}`

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className=""
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <div className="property-info-wrapper">
            <Container>
              <div className="property-info">
                <div className="property-left-info">
                  <h1 className="property-title">{`${titleText}`}</h1>
                  <p className="property_dec">{props.propertyData?.description}</p>
                  <motion.div variants={fadeInFromLeft} className="rooms-count">
                    <div className="bedrooms">
                      <i className="icon bed-icon" />{" "}
                      <span>
                        {props.propertyData?.bedroom}{" "}
                        {!isMobile ? "bedrooms" : ""}
                      </span>
                    </div>
                    <div className="bathroom">
                      <i className="icon bath-icon" />{" "}
                      <span>
                        {props.propertyData?.bathroom}{" "}
                        {!isMobile ? "bathrooms" : ""}
                      </span>
                    </div>
                    <div className="area-covered">
                      <i className="icon area-icon" />{" "}
                      <span>
                        {props.propertyData?.floorarea_max} sqft /{" "}
                        {Math.round(sqmeter)} sqm
                      </span>
                    </div>
                  </motion.div>
                  {isLargeScreen && (
                    <motion.div
                      variants={fadeInFromRight}
                      className="mobile-property-card-content"
                    >
                      <h3>AED {numberFormat(data?.price)}</h3>
                      <div className="mobile-property-card-section">
                        {data.search_type === "sales" && (
                          <p>
                            <i className="icon calculator-icon" />{" "}
                            <span onClick={() => handleClick()}>
                              Calculate the rental yield of this property*
                            </span>
                          </p>
                        )}
                        <div
                          className="mobile-share-property"
                          onClick={() => openShareicons()}
                        >
                          <i className="icon black-share-icon" />
                          <span>Share this Property</span>
                          {shareIcons && (
                            <SocialShareComponent
                              openShareicons={openShareicons}
                              shareurl={shareurl}
                            />
                          )}
                        </div>
                      </div>
                    </motion.div>
                  )}
                  {props.propertyData?.long_description && (
                    <motion.div
                      variants={fadeInFromLeft}
                      className="property-long-description"
                      id="contentsection"
                    >
                      <ReadMore
                        content={props.propertyData?.long_description}
                        height={260}
                        className={"description"}
                      />
                    </motion.div>
                  )}
                  {isLargeScreen && teamData && (
                    <motion.div
                      variants={fadeInFromRight}
                      className="mobile-property-team-block"
                    >
                      <div className="mobile-team-content-module">
                        <div className="img-team-section">
                          <img src={teamData?.image?.url} alt="property-team" />
                        </div>
                        <div className="mobile-property-team-info">
                          <h4>{teamData?.name}</h4>
                          <p className="desigination">
                            {teamData?.designation}
                          </p>
                          <p className="speaks">Speaks {teamData?.languages}</p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                  <div className="property-horizonatal-line"></div>
                  <motion.div
                    variants={fadeInFromLeft}
                    className="details-section-property"
                  >
                    <h3>Details</h3>
                    <div className="detail-block">
                      <div className="bedroom-count">
                        <p className="detail-sub-heading">Bedrooms</p>
                        <p>{data?.bedroom!==0?data?.bedroom:"-"}</p>
                        <div className="detail-vertical-line"></div>
                      </div>
                      <div className="bedroom-count">
                        <p className="detail-sub-heading">Bathrooms</p>
                        <p>{data?.bathroom}</p>
                        <div className="detail-vertical-line"></div>
                      </div>
                      <div className="bedroom-count">
                        <p className="detail-sub-heading">Size</p>
                        <p>{data?.floorarea_max} sq ft</p>
                        <div className="detail-vertical-line"></div>
                      </div>
                      {/* <div className="bedroom-count">
                  <p className="detail-sub-heading">Developer</p>
                  <p>Developer</p>
                  <div className="detail-vertical-line-mobile"></div>
                </div> */}
                      <div className="bedroom-count">
                        <p className="detail-sub-heading">Offering Type</p>
                        <p>{data?.status === "For Sale" ? "Buy" : "Let"}</p>
                        <div className="detail-vertical-line-mobile"></div>
                      </div>
                      <div className="bedroom-count">
                        <p className="detail-sub-heading">Permit No.</p>
                        <p>{data?.extra.permit_number}</p>
                      </div>
                    </div>
                  </motion.div>
                  <div className="property-horizonatal-line"></div>
                  <motion.div
                    variants={fadeInFromRight}
                    className="property-map-component"
                    id="map-component"
                  >
                    <h3>Location</h3>
                    <Map property_details_map_data={props.propertyData} />
                  </motion.div>
                  {data?.search_type === "sales" && (
                    <>
                      <RentalYieldCalculator data={props.propertyData} />
                      <div className="property-horizonatal-line horizon"></div>
                      <motion.div
                        variants={fadeInFromLeft}
                        className={`property-pre-approve`}
                      >
                        <div className="approve-content-block">
                          <div className="prop-image-section">
                            <img src={staticImage?.url} alt="static image" />
                          </div>
                          <div className="static-prop-content">
                            <h4>Get a pre-approved mortgage in Dubai</h4>
                            <p>
                              Speak with our mortgage broker and get started
                              with your mortgage.
                            </p>
                          </div>
                        </div>
                        <Link to={contactURL}>
                          <button className="button button-black">
                            get pre-approved
                          </button>
                        </Link>
                      </motion.div>
                    </>
                  )}
                </div>
                {!isLargeScreen ? (
                  <motion.div
                    variants={fadeInFromRight}
                    className="property-right-info"
                  >
                    <PropertyCardSection
                      data={props.propertyData}
                      teamData={props.teamData}
                    />
                  </motion.div>
                ) : (
                  <div
                    className={clsx("mobile-property-contact-ctas", {
                      // "grid-temp-1fr": !teamData,
                    })}
                  >
                    {props?.teamData?.mobile_no ? (
                      <a
                        href={"tel:" + props.teamData.mobile_no}
                        className="button button-black"
                      >
                        <i className="icon call-icon" />{" "}
                        <span>{isMobile ? "" : "call"}</span>
                      </a>
                    ) : (
                      <a
                        href={"tel:" + phone}
                        className="button button-black"
                      >
                        <i className="icon call-icon" />{" "}
                        <span>{isMobile ? "" : "call"}</span>
                      </a>
                    )}

                    <Link
                      className="button button-black middle-button"
                      to={bookViewing}
                      state={{
                        data: {
                          property: props.propertyData,
                          shareurl,
                        },
                      }}
                    >
                      <i className="icon envelope-icon" />{" "}
                      <span>{isMobile ? "" : "email"}</span>
                    </Link>
                    {props?.teamData?.mobile_no ? (
                      <a
                        className="button button-black"
                        href={getWhatsAppURL(props.teamData.mobile_no)}
                        target="_blank"
                      >
                        <i className="icon white-Whatsapp" />
                        <span>{isMobile ? "" : "whatsapp"}</span>
                      </a>
                    ) : (
                      <a
                        className="button button-black"
                        href={getWhatsAppURL(phone)}
                        target="_blank"
                      >
                        <i className="icon white-Whatsapp" />
                        <span>{isMobile ? "" : "whatsapp"}</span>
                     </a>
                    )}
                  </div>
                )}
              </div>
            </Container>
          </div>
        </motion.div>
      )}
    </InView>
  )
}

export default PropertyInformation
