import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Lightbox from "react-image-lightbox"
import "./PropertyBanner.scss"
import "react-image-lightbox/style.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import GetGGFXImage from "../common/site/GetGGFXImage"

const PropertyBanner = props => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const largeImage = props.propertyData?.images[0]
  const smallImage = props.propertyData?.images.slice(1, 3)

  const propertyImages = props.propertyData?.images
  var propertyLightImages =
    propertyImages &&
    propertyImages.map(img => (img.srcUrl ? img.srcUrl : img.url))
  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const showMapComponent = () => {
    const getId = document.getElementById("map-component")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const { isTablet } = useDeviceMedia()
  const imageCount = props.propertyData?.images?.length

  let processedImages = props?.imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <>
      <div className="property-details-wrapper">
        <Container>
          <div className="property-image-sections">
            <div className="large-image img-zoom">
              <a
                href="javascript:void(0)"
                onClick={e => openPropertyImage(e, 0)}
              >
                <GetGGFXImage
                  imagename={"property.images.propertyDetailLarge"}
                  imagesource={largeImage}
                  fallbackalt={props.propertyData?.description}
                  imagetransformresult={processedImages}
                  id={props.propertyData?.strapiId}
                />
              </a>
              <div className="property-image-containers">
                {props.propertyData?.images &&
                  props.propertyData?.images?.length > 0 && (
                    <div className="view-images-property">
                      <a
                        href="javascript:void(0)"
                        onClick={e => openPropertyImage(e, 0)}
                      >
                        <i className="icon prop-list-image"></i>{" "}
                        <span>{imageCount} Photos</span>
                      </a>
                    </div>
                  )}
                <div className="property-view-map">
                  <a
                    href="javascript:void(0)"
                    onClick={() => showMapComponent()}
                  >
                    <i className="icon map-pin" /> <span>View on map</span>
                  </a>
                </div>
              </div>
            </div>
            {!isTablet && (
              <div className="small-image">
                {smallImage &&
                  smallImage.map((image, index) => (
                    <a
                      href="javascript:void(0)"
                      onClick={e => openPropertyImage(e, index + 1)}
                      className="img-zoom"
                    >
                      <GetGGFXImage
                        imagename={"property.images.tileimg"}
                        imagesource={image}
                        fallbackalt={props.propertyData?.description}
                        imagetransformresult={processedImages}
                        id={props.propertyData?.strapiId}
                        className={`image-index-${index}`}
                      />
                    </a>
                  ))}
              </div>
            )}
          </div>
        </Container>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
    </>
  )
}

export default PropertyBanner
