import { useQuery } from "@apollo/client"
import React from "react"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import PropertyInformation from "../components/PropertyInformation/PropertyInformation"
import { People_Email } from "../queries/common_use_query"
import SimilarProperties from "../components/SimilarProperties/SimilarProperties"
import { propertyForSaleURL, propertyForRentURL } from "../site/urls"
import logoBlack from "../images/logo-black.svg"
import SEO from "../components/Seo/seo"
import { numberFormat } from "../components/common/site/utils"
import NotFoundPage from "../pages/404"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import PropertyStructuredData from "../components/Seo/PropertyStructuredData"

const PropertyDetailTemplate = ({ data }) => {
  const { phone } = useCompanyInfo()
  const { isLargeScreen } = useDeviceMedia()
  // const PROPERTY_DETAILS = gql`
  //   query GetProperty($id: String!) {

  //   }
  // `

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  // let splitQuery = pageurl.split("?")
  // let mypageurl = splitQuery[0]
  // let property_slug = mypageurl.split("-")
  // let id = property_slug[property_slug.length - 1]
  // id = id.replace("/", "")
  // const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
  //   variables: { id: id },
  // })


  const propertyData = data?.strapiProperty
  const negotiatorEmail = propertyData?.crm_negotiator_id?.name
  const peopleData = People_Email(negotiatorEmail);
  // const  teamResult =
  //   peopleData?.data?.teams?.length > 0 && peopleData?.data?.teams[0];
  const teamData = peopleData?.data?.teams?.find(data => data.name === negotiatorEmail)

  const propertyStatus = propertyData?.status?.toLowerCase()
  const staticURLs = [
    {
      url: `${propertyStatus === "for sale" ? propertyForSaleURL : propertyForRentURL
        }in-dubai/`,
      label: `Properties ${propertyStatus} in Dubai`,
    },
    { url: "", label: propertyData?.display_address },
  ]

  // Loading logo
  // if (loading) {
  //   return (
  //     <section className={"loader-wrapper"}>
  //       <div id="loader-wrapper">
  //         <div id="loader" class="new-loader">
  //           <img
  //             className="logo-white loader-logo"
  //             src={logoBlack}
  //             alt="logo"
  //           />
  //         </div>
  //       </div>
  //     </section>
  //   )
  // }


  // if(data?.properties?.length===0){
  //   return (
  //     <NotFoundPage/>
  //   )
  // }


  const building = propertyData?.building?.length > 0 ? propertyData?.building[0] : ""
  const action = propertyData?.search_type === "sales" ? "for sale" : "for rent"


  // Loading logo
  const title = `${building} ${action} with ${propertyData?.bedroom} bedrooms in ${propertyData?.display_address} at AED ${numberFormat(propertyData?.price)}`
  const description = `Know the details of ${building?.toLowerCase()} ${action} with ${propertyData?.bedroom} bedrooms in ${propertyData?.display_address} at AED ${numberFormat(propertyData?.price)}. Book a viewing to get assistance in finding the right ${building.toLowerCase()} for you.`
  const image = propertyData?.images?.length > 0 ? propertyData.images[0].url : ""

  return (
    <>
      <PropertyStructuredData
        property={propertyData}
        metadesc={description}
        pageurl={pageurl}
      />
      <div className="property-details-page">
        {image &&
          <SEO
            title={title}
            description={description}
            image={image}
          />
        }
        <Header />
        {!isLargeScreen &&
          <div className="social-section">
            <a href={getWhatsAppURL(phone)} target="_blank">
              <img
                src={whatsappImg}
                alt="social-whatsapp"
                className="social-icon social-whatsapp"
              />
            </a>
            <a href={"tel:" + phone}>
              <img
                src={balckPhoneImg}
                alt="social-phone"
                className="social-icon social-phone"
              />
            </a>
          </div>
        }
        <Breadcrumbs staticURLs={staticURLs} />
        <PropertyBanner propertyData={propertyData} />
        <PropertyInformation propertyData={propertyData} teamData={teamData} />
        <SimilarProperties propertyData={propertyData} />
        <Footer categoryType="category1" />
      </div>
    </>
  )
}

export default PropertyDetailTemplate

export const query = graphql`
  query ($strapiId: String!) {
    strapiProperty(strapiId: {eq: $strapiId}) {
      id
      strapiId
      accomadation_summary
      address {
        address1
        address2
        address3
        address4
        building_name
        postcode
      }
      area
      available_from
      bathroom
      bedroom
      building
      crm_id
      crm_negotiator_id {
        email
        name
        phone
        url
      }
      crm_provider
      department
      description
      display_address
      extra {
        unit_reference_no
        unit_model
        tenanted
        property_name
        primary_view
        price_on_application
        preview_link
        permit_number
        off_plan
        no_of_rooms
        fitted
        featured
        exclusive
        completion_status
        company_name
      }
      floorarea_max
      floorarea_min
      floorarea_type
      images {
        srcUrl
        url
      }
      imagetransforms {
        images_Transforms
      }
      long_description
      search_type
      price
      price_qualifier
      property_url
      slug
      title
      latitude
      status
      longitude
    }
  }
`
