import axios from "axios"
import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { getStrapiDataFromURL } from "../common/site/utils"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./SimilarProperties.scss"
import {
  inViewOptions2,
  fadeInFromTop,
  containerVariants
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const SimilarProperties = props => {
  const [propertyLists, setPropertyLists] = useState([])

  const getitems = async url => {
    const similarProperties = await getStrapiDataFromURL(url)
    setPropertyLists(similarProperties)
  }

  const propertyData = props.propertyData

  useEffect(() => {
    if (props?.propertyData?.strapiId) {
      let url =
        `/stb-lists/item/Property-details?pid=` + props?.propertyData?.strapiId
      getitems(url)
    }
  }, [props.propertyData])

  let uriStr = ""
  if (
    propertyData?.search_type === "sales" &&
    propertyData?.department === "residential"
  ) {
    uriStr = `property-for-sale`
  } else if (
    propertyData?.search_type === "lettings" &&
    propertyData?.department === "residential"
  ) {
    uriStr = `property-for-rent`
  }

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className=""
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
    <>
      {propertyLists && propertyLists.length > 0 && (
        <motion.div variants={fadeInFromTop} className="similar-properties-wrapper">
          <Container>
            <h2>Similar Properties in {propertyData?.area}</h2>

            <CustomSlider className="similar-properties-slider" length={propertyLists?.length}>
              {propertyLists.map((item, index) => (
                <div className="similar-properties-card">
                  <Link
                    to={`/${uriStr}/${item?.slug}-${item?.id}/`}
                  >
                    <div className="image-section">
                      <img src={item.images[0].url} alt={item.title} />
                      <div className="image-tag">
                        <p className="tag-name">{item?.building[0]}</p>
                      </div>
                    </div>
                  </Link>
                  <div className="content">
                    <p className="price">
                      AED {parseFloat(item?.price).toLocaleString()}
                    </p>
                    <Link
                      to={`/${uriStr}/${propertyData?.slug}-${propertyData?.id}/`}
                    >
                      <p className="address">{item.display_address}</p>
                    </Link>
                    <p className="description">{item.description}</p>
                  </div>
                </div>
              ))}
            </CustomSlider>
          </Container>
        </motion.div>
      )}
    </>
    </motion.div>
      )}
    </InView>
  )
}

export default SimilarProperties
